import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import IQACSidebar from './IQACSidebar';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import { Link } from 'react-router-dom';
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class IQACNAACCRITERION_7 extends Component {

  state = { Datas: [] }

  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "IQSC", "NAAC_Criterion_7");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign(
          {
            Id: key,
            Priority: value.Priority != undefined ? value.Priority : "999",
            Questionnaire: value.Questionnaire,
            Title: value.Title,
          }))
      });

      Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

    }

    else { this.setState({ Datas: "NO_999" }) }
  }

  render() {
    return (
      <div className="body_wrapper">

        <Header />
        <Banner title="IQAC" image="banners/About.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">NAAC - CRITERION 7 - Institutional Values and Best Practices</h4>
                  </div>
                </div>
                {
                  this.state.Datas.length === 0 ? <Loading /> :
                    this.state.Datas === "NO_999" ? <NoData /> :
                      this.state.Datas.map((data, i) => (
                        <div>
                          <table class="table">
                            <thead>
                              <tr style={{ background: 'darkblue', color: 'white' }}>
                                <th scope="col">Metric No</th>
                                <th scope="col">{data.Title}</th>
                                <th scope="col">Documents</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data.Questionnaire.map((row, i) => (
                                  <tr>
                                    <td>{row.Val_1}</td>
                                    <td>{row.Val_2}</td>
                                    <td><a href={row.Val_3} target='_blank'>View</a></td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      ))
                }
                <div className="row paddingtop-2" style={{ fontWeight: "bold" }}>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-1">CRITERION 1</Link></div>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-2">CRITERION 2</Link></div>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-3">CRITERION 3</Link></div>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-4">CRITERION 4</Link></div>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-5">CRITERION 5</Link></div>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-6">CRITERION 6</Link></div>
                  <div className="col-lg-2"><Link to="/IQAC-NAAC-CRITERION-7">CRITERION 7</Link></div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <IQACSidebar />
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
