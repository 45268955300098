import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import IQACSidebar from './IQACSidebar';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class IQACAISHE extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "IQSC", "AISHE");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Content: value.Content,
                        Header: value.Header,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="AISHE" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">AISHE Details</h4>
                                    </div>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl No</th>
                                            <th scope="col">Header</th>
                                            <th scope="col">PDF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.Datas.length === 0 ? <Loading /> :
                                                this.state.Datas === "NO_999" ? <NoData /> :
                                                    this.state.Datas.map((data, i) => (
                                                        <tr>
                                                            <td>{i+1}</td>
                                                            <td>{data.Header}</td>
                                                            <td><a target="_blank" href={data.Content}>Click Here</a></td>
                                                        </tr>
                                                    ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <IQACSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

