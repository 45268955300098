import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBRow } from "mdbreact";
import { Link } from "react-router-dom";

export class CarouselPage extends Component {
    render() {
        return (
            <div>
                <MDBContainer fluid className="hide-on-mob">
                    <MDBRow>
                        <MDBCarousel activeItem={1} length={4} showControls={true} showIndicators={false} id="Home_banner">
                            <MDBCarouselInner>
                                <MDBCarouselItem itemId="1">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander01.jpg"} alt="First slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl-1">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Every society is built on the</h3><strong className="ttm-textcolor-skincolor"></strong>Rule of law</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                                    <p className="text" style={{ fontWeight: "bold" }}>‘which also produces law-abiding citizens, lawyers,<br />academics, and future judges’</p>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme-Offered">Programme Offered</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="2">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander02.jpg"} alt="Second slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl-1">
                                            <div className="carousel-caption-inner banner2">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Question everything, have</h3><strong className="ttm-textcolor-skincolor"></strong>A spirit of enquiry</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                                    <p className="text" style={{ fontWeight: "bold" }}>but have fun too in College</p>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme-Process">Admission Process</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="3">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander03.jpg"} alt="Third slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl-1">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Law is valuable</h3><strong className="ttm-textcolor-skincolor"></strong>Not because <br />it is a law</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="9.5s" >
                                                    <p className="text" style={{ fontWeight: "bold" }}>but because there is right in it”</p>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Life-Scope">Teaching Staff</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="4">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander04.jpg"} alt="Second slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl-1">
                                            <div className="carousel-caption-inner banner2">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Law school is a</h3><strong className="ttm-textcolor-skincolor"></strong>A journey, not a Desitinaion</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                                    <p className="text" style={{ fontWeight: "bold" }}>It's a time to learn, grow, and challenge yourself. Embrace the challenges and enjoy the ride.</p>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Contact">Contact Us</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer fluid className="show-on-mob">
                    <MDBRow>
                        <MDBCarousel activeItem={1} length={4} showControls={true} showIndicators={false} id="Home_banner">
                            <MDBCarouselInner>
                                <MDBCarouselItem itemId="1">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider01.jpg"} alt="First slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Every society is built on the</h3><strong className="ttm-textcolor-skincolor"></strong>Rule of law</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme-Offered">Programme Offered</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="2">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider02.jpg"} alt="Second slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner2">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Question everything, have</h3><strong className="ttm-textcolor-skincolor"></strong>A spirit of enquiry</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme-Process">Admission Process</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="3">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider03.jpg"} alt="Third slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Law is valuable</h3><strong className="ttm-textcolor-skincolor"></strong>Not because it is a law</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="9.5s" >
                                                    <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Life-Scope">Teaching Staff</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="4">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider04.jpg"} alt="Second slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner2">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Law school is a journey,</h3><strong className="ttm-textcolor-skincolor"></strong>Not a Desitinaion</h2>
                                                </MDBAnimation>
                                                <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                    <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Contact">Contact Us</Link></p>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default CarouselPage;