import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import StatutorySidebar from './StatutorySidebar';

export default class StatutoryMinority extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Committess.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Minority Cell</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/statutory/statutory-1_1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">The minority cell of the college is established with the purpose of empowering the minority communities in the college. The Minority Cell basically helps minority students including Buddhist, Christian, Jain, Muslim, Sikh etc. for their academic development. The cell enables to build Equity, Equality and Access to all members of minority group and it also helps to manage end to end academic and non-academic concerns</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="about-para paddingtop-1">with regard to cast, social, economic, racial, lingual aspects in minority group.</p>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Objectives</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>It helps to enhance equal opportunities for education of Minorities.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To ensure provisions for an environment where all such students feel safe and secure.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To promote social inclusion and provide fairness and equal opportunity for the college minority community.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To encourage for participation in various orientation programs which would empower them with the necessary skills to choose a career option.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To provide counselling for any emotional emergencies arising on account of any event at the campus.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To facilitate financial support to students from minority communities from governmental agencies and other sources.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Activities</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To collect reports and information of State Government and UGC's orders on various aspects of the education, employment of Minority Students.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To circulate State Government and UGC's decisions about different scholarship programs.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To communicate with the students and motivate them for better future planning.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Grievance Redressal</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The complaint forms are made available with the Convenor of the Cell. Aggrieved parties have to fill the complaint form and submit the same along with necessary supporting documents (If any), for further course of action and redressal.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The grievant and the concerned persons to the case will be heard by the cell. Convener and members to understand the view points and redress the case in an unbiased manner.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The cell assures to provide an equitable solution within 7 working days.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}