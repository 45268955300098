import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import IQACSidebar from './IQACSidebar';

export default class IQACAbout extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="IQAC" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Internal Quality Assurance Cell (IQAC)</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">In pursuance of the National Assessment and Accreditation Council (NAAC), for performance evaluation, assessment and accreditation and quality up-gradation of institutions of higher education, the NAAC proposes that every accredited institution establish an Internal Quality Assurance Cell (IQAC) as a post-accreditation quality sustenance measure.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">Accordingly, Our College has established Internal Quality Assurance Cell (IQAC) with a primary responsibility to initiate, Plan and supervise various activities that are necessary to enhance the quality of the legal education imparted in the college.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Objectives</h5>
                                        </div>
                                    </div>
                                    <p>The primary aim of IQAC is</p>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the institution.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To promote measures for institutional functioning towards quality enhancement through internalization of quality culture and institutionalization of best practices.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Strategies</h5>
                                        </div>
                                    </div>
                                    <p>IQAC shall evolve mechanisms and procedures for</p>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Ensuring timely, efficient and progressive performance of academic, administrative and financial tasks.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Relevant and quality academic/ research programmes.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Equitable access to and affordability of academic programmes for various sections of society.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Optimization and integration of modern methods of teaching and learning.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The credibility of assessment and evaluation process.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Ensuring the adequacy, maintenance and proper allocation of support structure and services.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Sharing of research findings and networking with other institutions in India and abroad.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Functions of the IQAC</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Development and application of quality benchmarks Parameters for various academic and administrative activities of the institution.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Facilitating the creation of a learner-centric environment conducive to quality education and faculty maturation to adopt the required knowledge and technology for participatory teaching and learning process.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Collection and analysis of feedback from all stakeholders on quality-related institutional processes.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Dissemination of information on various quality parameters to all stakeholders.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Organization of inter and intra institutional workshops, seminars on quality related themes and promotion of quality circles.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Documentation of the various programmes/activities leading to quality improvement.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Acting as a nodal agency of the Institution for coordinating quality-related activities, including adoption and dissemination of best practices.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Development and maintenance of institutional database through MIS for the purpose of maintaining /enhancing the institutional quality.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Periodical conduct of Academic and Administrative Audit and its follow-up.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Preparation and submission of the Annual Quality Assurance Report (AQAR) as per guidelines and parameters of NAAC.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <IQACSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
