import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import IQACSidebar from './IQACSidebar';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class IQACMeeting extends Component {

  state = { Datas: [] }

  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "IQSC", "Meeting");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign(
          {
            Id: key,
            Priority: value.Priority != undefined ? value.Priority : "999",
            Year: value.Year,
            Link: value.Link,
          }))
      });

      Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

    }

    else { this.setState({ Datas: "NO_999" }) }
  }

  render() {
    return (
      <div className="body_wrapper">

        <Header />
        <Banner title="IQAC" image="banners/About.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Minutes of Meeting</h4>
                  </div>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sl No</th>
                      <th scope="col">Year</th>
                      <th scope="col">Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.Datas.length === 0 ? <Loading /> :
                        this.state.Datas === "NO_999" ? <NoData /> :
                          this.state.Datas.map((data, i) => (
                            <tr key={i}>
                              <td>1</td>
                              <td>{data.Year}</td>
                              <td><a href={data.Link} target="_blank">Click Here</a></td>
                            </tr>
                          ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-lg-3 widget-area">
                <IQACSidebar />
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
