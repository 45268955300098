import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class ProgrammeOffered extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Programme" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/programme/programme-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The College offers both BA. LL.B (5 year) integrated programme and LL.B. (3year) as part of its undergraduate programme to prepare legal professionals for the diverse contexts. A law graduate is faced with a variety of career options. Judicial Services is well known and the traditional career path. A flourishing legal industry is growing in lockstep with India’s economic growth creating extraordinary opportunities for lawyers and law firms. Various opportunities in Alternative Dispute Resolutions practices. National and Multinational Corporations offer diverse career opportunities in legal services and departments, covering areas like International Trade and Commerce, Intellectual Property Rights, Global Mobility and International Laws.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">The emergence of LPO (Legal Process Outsourcing) opens up career opportunities in several specialized legal services, particularly in Legal Research.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">5 Year B.A.LL.B. </h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">The duration of the programme leading to the integrated Bachelor's Degree BA. LLB. Shall be of five academic Year. Each academic year comprises of two semesters. A student who successfully completes the five year B.A.LL.B., integrated degree programme shall be eligible for the award of the law Degree- B.A.LL.B.</p>
                                <a href={process.env.PUBLIC_URL + "/assets/images/programme/5_Years_LLB.pdf"} download style={{color:"#8B4000", fontWeight:"bold"}}>Download Course Structure PDF</a>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">3 Year LL.B. </h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">The duration of the course leading to the Bachelor's Degree in Law (LL.B) shall be of three academic Year. Each academic year comprises of 2 semesters. A student who successfully completes the three year LLB. Degree programme shall be eligible for the award of the law Degree- LL.B.</p>
                                <a href={process.env.PUBLIC_URL + "/assets/images/programme/3_Years_LLB.pdf"} download style={{color:"#8B4000", fontWeight:"bold"}}>Download Course Structure PDF</a>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
