import React, { Component } from 'react';

import Header from '../layout/Header';
import Banner from '../Common/Banner';

import IQACSidebar from './IQACSidebar';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class IQACDVV extends Component {

  state = { Data1: [], Data2: [] }

  async componentDidMount() {
    // Fetch data from the first document
    const docRef1 = doc(firestore, "IQSC", "DVV_1");
    const docSnap1 = await getDoc(docRef1);
    if (docSnap1.exists()) {
      const data1 = [];
      Object.entries(docSnap1.data()).forEach(([key, value]) => {
        data1.push({
          Id: key,
          Priority: value.Priority != undefined ? value.Priority : "999",
          Sl: value.Sl,
          Particulars: value.Particulars,
          Link: value.Link,
        });
      });
      data1.length === 0 ? this.setState({ Data1: "NO_999" }) : this.setState({ Data1: data1.sort(function (a, b) { return a.Priority - b.Priority; }) })
    }
    else { this.setState({ Data1: "NO_999" }) }

    // Fetch data from the second document
    const docRef2 = doc(firestore, "IQSC", "DVV_2");
    const docSnap2 = await getDoc(docRef2);
    if (docSnap2.exists()) {
      const data2 = [];
      Object.entries(docSnap2.data()).forEach(([key, value]) => {
        data2.push({
          Id: key,
          Priority: value.Priority != undefined ? value.Priority : "999",
          Sl: value.Sl,
          Particulars: value.Particulars,
          Link: value.Link,
        });
      });
      data2.length === 0 ? this.setState({ Data2: "NO_999" }) : this.setState({ Data2: data2.sort(function (a, b) { return a.Priority - b.Priority; }) })
    }
    else { this.setState({ Data2: "NO_999" }) }
  }

  render() {
    return (
      <div className="body_wrapper">

        <Header />
        <Banner title="IQAC" image="banners/About.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">DVV - Extended Profile Deviations</h4>
                  </div>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Metric Id</th>
                      <th scope="col">Deviation Details</th>
                      <th scope="col">Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.Data1.length === 0 ? <Loading /> :
                        this.state.Data1 === "NO_999" ? <NoData /> :
                          this.state.Data1.map((data, i) => (
                            <tr key={i}>
                              <td>{data.Sl}</td>
                              <td>{data.Particulars}</td>
                              <td><a href={data.Link} target="_blank">Click Here</a></td>
                            </tr>
                          ))
                    }
                  </tbody>
                </table>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">DVV - Metrics Level Deviations</h4>
                  </div>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Metric Id</th>
                      <th scope="col">Deviation Details</th>
                      <th scope="col">Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.Data2.length === 0 ? <Loading /> :
                        this.state.Data2 === "NO_999" ? <NoData /> :
                          this.state.Data2.map((data, i) => (
                            <tr key={i}>
                              <td>{data.Sl}</td>
                              <td>{data.Particulars}</td>
                              <td><a href={data.Link} target="_blank">Click Here</a></td>
                            </tr>
                          ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-lg-3 widget-area">
                <IQACSidebar />
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
