import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

import NoData from '../Common/NoData';
import Loading from '../Common/Loading';
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class Calendar extends Component {

  state = { Datas: [] }

  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "IQSC", "Calendar");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign(
          {
            Id: key,
            Priority: value.Priority != undefined ? value.Priority : "999",
            Year: value.Year,
            Link: value.Link,
          }))
      });

      Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

    }

    else { this.setState({ Datas: "NO_999" }) }
  }

  render() {
    return (
      <div>
        <Header />
        <Banner title="Calendar" image="banners/Programme.jpg" />
        <div className="container-xl paddingtop-2">
          <div className="ttm-service-single-content-area">
            <div className="ttm-service-description">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Sl No</th>
                    <th scope="col">Year</th>
                    <th scope="col">Link</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.Datas.length === 0 ? <Loading /> :
                      this.state.Datas === "NO_999" ? <NoData /> :
                        this.state.Datas.map((data, i) => (
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td>{data.Year}</td>
                            <td><a href={data.Link} target="_blank">Click Here</a></td>
                          </tr>
                        ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br/><br/>
      </div>
    )
  }
}
