import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import IQACSidebar from './IQACSidebar';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class IQACComposition extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "IQSC", "Composition");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Questionnaire: value.Questionnaire,
                        Title: value.Title,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="IQAC" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Composition of IQAC</h4>
                                    </div>
                                </div>
                                {
                                    this.state.Datas.length === 0 ? <Loading /> :
                                        this.state.Datas === "NO_999" ? <NoData /> :
                                            this.state.Datas.map((data, i) => (
                                                <div>
                                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                                            <h5 className="title">{data.Title}</h5>
                                                        </div>
                                                    </div>
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sl No</th>
                                                                <th scope="col">MEMBERS</th>
                                                                <th scope="col">DESIGNATION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.Questionnaire.map((row, i) => (
                                                                    <tr>
                                                                        <td>{row.Val_1}</td>
                                                                        <td>{row.Val_2}</td>
                                                                        <td>{row.Val_3}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ))
                                }
                            </div>
                            <div className="col-lg-3 widget-area">
                                <IQACSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
