import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import StatutorySidebar from './StatutorySidebar';

export default class StatutoryAntiRagging extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Committess.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Anti-Ragging Cell</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/statutory/statutory-3.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">Anti-Ragging Cell should be one of the important parts of Educational Institution’s mechanism. As per the guidelines of UGC under the Act of 1956, which is modified as UGC regulations on curbing the menace of Ragging in higher Educational Institutions, 2009, establishment of Anti-Ragging Cell is very compulsory.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">The Cell's responsibility is to ensure that students maintain discipline in the classrooms and within the college campus. Members of the cell regularly go on rounds to each and every classroom to make note of delinquent behavior and to make sure that the college campus is free from ragging and eve-teasing. The Cell takes severe punitive action against students indulging in such vicious activities.</p>
                                            </div>
                                            <div className="col-md-12 justify">
                                                <p className="about-para">In pursuance to the judgment of the Hon’ble Supreme Court of India and subsequent Notification from University Grants Commission (UGC), ragging constitutes one or more of any intention by any student or group of students on:</p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any act of Indiscipline, Teasing or Handling with Rudeness.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any act that Prevents, Disrupts the Regular Academic Activity.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any activity which is likely to cause Annoyance, hardship, Psychological Harm or creates Fear or Apprehension.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any Act of Financial Extortion or Forceful Expenditure.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any Act of Physical Abuse causing Assault, Harm or danger to Health.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any Act of abuse by spoken words, emails, SMS or public insult etc.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any Act of injury or infringement of the fundamental right to the human dignity.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Any Act of Wrongful Confinement, Kidnapping, molesting or committing unnatural offences, use of criminal forces, trespass or intimidation.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Punishment to those found guilty</h5>
                                        </div>
                                    </div>
                                    <p className="about-para">Any student or group of students found guilty of ragging in the campus or even outside the campus shall be liable to one or more of the following punishments</p>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Debarring from appearing in any sessional test / University Examination</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Suspension from attending classes and academic privileges</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Withdrawing scholarships and other benefits</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Suspension from the college</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Cancellation of the admission</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"/>Withholding the results.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}