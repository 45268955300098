import React, { Component } from 'react';
import { Header } from './layout/Header';
import { CarouselPage } from './Common/Home1_banner';
import 'react-image-lightbox/style.css';
import Notification from './Common/Notification';
import Number from './Common/Number';

export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {

    return (
      <div className="site-main">
        <Header />
        <CarouselPage />
        <Notification />
        <Number />
      </div>
    )
  }
}

export default Home;