import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutCollege extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="About College" image="banners/About.jpg" />
                
                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutcollege/college-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Visveswarapura College of Law (V.V.Pura College of law) was established by the Rajya Vokkaligara Sangha, Bengaluru in the year 1972. Its founder Principal was a great legal luminary and former Advocate General of Karnataka High Court Prof.B.T.Parthasarathy. Our College is one of the prestigious Law Colleges in Karnataka, doing Yeoman’s service to the nation.  Our College is providing great educational opportunities to both Rural and Urban Students, to study Law courses. The institution is imparting innovative and quality legal education with a vision to become an outstanding learning center with a global outlook and human values.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">Today V.V.Pura College of Law offers both 3 Year LL.B. & 5 Year B.A.LL.B. Courses. It is recognised by the Bar Council of India (BCI), New Delhi and is permanently affiliated to the Karnataka State Law University (KSLU), Hubballi.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">Its alumni have settled in ranges of legal professions and have left their enduring mark in the legal field in various parts of India and abroad. It is committed to focus on multi-disciplinary approach to enable more students to access affordable and quality legal education along with multiple skill sets. It focuses on creating a system by which participation in moots and internships in courts and similar institutions for students so they are able to hold their own from day one.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Vision</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">To establish an advanced center of higher learning in legal education by creating a sense of professionalism, ethically strong, socially sensitive and skilled legal and Social Architects.</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Mission</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To impart conversant & competent practices.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To advocate the values of democracy & social commitment.</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To support community outreach services.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To foster integrity, diversity & inclusiveness.</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
