import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class IQACSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to={'/IQAC-About'}>About IQAC</NavLink></li>
                    <li><NavLink className="nav-link" to={'/IQAC-Composition'}>Composition of the IQAC</NavLink></li>
                    <li><NavLink className="nav-link" to={'/IQAC-Meeting'}>Minutes of Meeting</NavLink></li>
                    <li><NavLink className="nav-link" to={'/IQAC-NAAC'}>NAAC</NavLink></li>
                    <li><NavLink className="nav-link" to={'/IQAC-DVV'}>DVV</NavLink></li>
                    <li><NavLink className="nav-link" to={'/IQAC-AISHE'}>AISHE</NavLink></li>
                </ul>
            </div>
        )
    }
}