import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class LibrarySidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to={'/Library-About'}>About Library</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Library-Rules'}>Rules and Regulation</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Library-Infrastructure'}>Infrastructure / Facilities</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Library-Staff'}>Library Staff Information</NavLink></li>
                </ul>
            </div>
        )
    }
}