import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import LibrarySidebar from './LibrarySidebar';

export default class LibraryAbout extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                <Banner title="Library" image="banners/Library.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/library/library-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">Visveswarapura College of Law (V.V.Pura College of law), is founded on the maxims of ‘Ignoranti Juris neminem excusat’ and ‘Lex facit Regem’. The Legal System in our country is based on the principle ‘Ignorantia Juris neminem excusat’ (Ignorance of Law Excuses no one), which is the presumption that all the citizens should be acquainted with law and are</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">expected to follow the same, in its letter and spirit. One of the reasons for the above principle, besides social obligations is depicted in the vision statement of the Visveswarapura College of Law. The strength of legal education is also found in the maxim ‘Lex facit Rege m’ (law makes the King) If the law is stronger than the king himself, the significance of law and legal education in the life of a common man is of utmost importance. Thus, we can see that, Visveswarapura College of Law with a vision to provide quality based legal education.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Vision of the Library</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To increase usage of e-resources.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To promote reading habits of students.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To be a center for e-learning.</li>
                                                </div>
                                                <div className="col-md-6">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Mission of the Library.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To expand the digital library collection.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To be a knowledge hub providing efficient services.</li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Goal</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <p className="tab-desc">To be a knowledge center, enhance skill sets and foster research culture.</p>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Library Services</h4>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sl No</th>
                                                <th scope="col">Library Working Hours & Hours</th>
                                                <th scope="col">Monday to Friday</th>
                                                <th scope="col">Saturday</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Reference Section</td>
                                                <td>8 AM to 5 PM</td>
                                                <td>8 AM to 1 PM</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Issue Section</td>
                                                <td>8 AM to 5 PM</td>
                                                <td>8 AM to 1 PM</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Periodical Section</td>
                                                <td>8 AM to 5 PM</td>
                                                <td>8 AM to 1 PM</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Digital Library</td>
                                                <td>8 AM to 5 PM</td>
                                                <td>8 AM to 1 PM</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Book Bank</td>
                                                <td>8 AM to 5 PM</td>
                                                <td>8 AM to 1 PM</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Reading Room</td>
                                                <td>8 AM to 5 PM</td>
                                                <td>8 AM to 1 PM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><b>Note : </b>Closed on Sundays and Public Holiday</p>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LibrarySidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
