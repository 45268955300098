import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBsbVg_vmdKiELZFh2E40VS2-D62ALbo_Y",
  authDomain: "rvs-law.firebaseapp.com",
  projectId: "rvs-law",
  storageBucket: "rvs-law.appspot.com",
  appId: "1:524352672817:web:fc68222def7ddf5f3fb110",
  measurementId: "G-B0XSLHFXWR"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {auth, firestore , storage};