import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../layout/Header';
import Banner from '../Common/Banner';

import IQACSidebar from './IQACSidebar';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';


export default class IQACNAAC extends Component {

    state = { Data1: [], Data2: [] }

    async componentDidMount() {
        // Fetch data from the first document
        const docRef1 = doc(firestore, "IQSC", "IIQA");
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            const data1 = [];
            Object.entries(docSnap1.data()).forEach(([key, value]) => {
                data1.push({
                    Id: key,
                    Priority: value.Priority != undefined ? value.Priority : "999",
                    Sl: value.Sl,
                    Particulars: value.Particulars,
                    Link: value.Link,
                });
            });
            this.setState({ Data1: data1 });
        }

        // Fetch data from the second document
        const docRef2 = doc(firestore, "IQSC", "SSR");
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
            const data2 = [];
            Object.entries(docSnap2.data()).forEach(([key, value]) => {
                data2.push({
                    Id: key,
                    Priority: value.Priority != undefined ? value.Priority : "999",
                    Sl: value.Sl,
                    Particulars: value.Particulars,
                    Link: value.Link,
                });
            });
            this.setState({ Data2: data2 });
        }
    }

    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="IQAC" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">NAAC</h4>
                                    </div>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl No</th>
                                            <th scope="col">Criteria</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-1">CRITERION 1: CURRICULAR ASPECTS</Link></td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-2">CRITERION 2: TEACHING LEARNING AND EVALUATION</Link></td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-3">CRITERION 3: RESEARCH, INNOVATIONS AND EXTENSION</Link></td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-4">CRITERION 4: INFRASTRUCTURE AND LEARNING RESOURCES</Link></td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-5">CRITERION 5: STUDENT SUPPORT AND PROGRESSION</Link></td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-6">CRITERION 6: GOVERNANCE, LEADERSHIP AND MANAGEMENT</Link></td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td><Link to="/IQAC-NAAC-CRITERION-7">CRITERION 7: INSTITUTIONAL VALUES AND BEST PRACTICES</Link></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">IIQA</h4>
                                    </div>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl No</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.Data1.length === 0 ? <Loading /> :
                                                this.state.Data1 === "NO_999" ? <NoData /> :
                                                    this.state.Data1.map((data, i) => (
                                                        <tr key={i}>
                                                            <td>{data.Sl}</td>
                                                            <td>{data.Particulars}</td>
                                                            <td><a href={data.Link} target="_blank">Click Here</a></td>
                                                        </tr>
                                                    ))
                                        }
                                    </tbody>
                                </table>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">SSR</h4>
                                    </div>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl No</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.Data2.length === 0 ? <Loading /> :
                                                this.state.Data2 === "NO_999" ? <NoData /> :
                                                    this.state.Data2.map((data, i) => (
                                                        <tr key={i}>
                                                            <td>{data.Sl}</td>
                                                            <td>{data.Particulars}</td>
                                                            <td><a href={data.Link} target="_blank">Click Here</a></td>
                                                        </tr>
                                                    ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <IQACSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

