import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import StatutorySidebar from './StatutorySidebar';

export default class StatutoryICC extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Committess.jpg" />
                
                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">ICC / Prevention of Sexual Harassment Committee</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/statutory/statutory-4.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">As per the guidelines of UGC and the Supreme Court of India, an Anti-Sexual Harassment Committee has been established in the college to provide a healthy and congenial environment to the staff, students and other employees of the institution.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Aims and objectives of the committee</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To prevent sexual harassment by promoting gender justice and gender equality among the staff, students and other employees.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To create a secure physical and social environment to deter any act of sexual harassment.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To ensure support to the victim and prevention and termination of harassment.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To recommend appropriate redressal and punitive action against the guilty.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To promote awareness about sexual harassment through educational initiatives and foster a respectable, safe and conducive environment.</li>
                                    </ul>
                                    <p className="about-para paddingtop-1">The Committee against Sexual Harassment was formed as part of a larger institutional framework for the creation of a safe, equitable and inclusive campus environment. The Committee’s chief mandate includes considering complaints or grievances in relation to sexual harassment offences committed by students, faculty, staff and visitors on campus, and awareness-raising.</p>
                                    <p className="about-para">We understand that awareness and sensitization are crucial to preventing sexual harassment. The Committee conducts sensitization aimed at facilitating respectful interaction between people and empowering people to speak out about any experience of harassment or any barriers that they face in college.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}