import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutChairman extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Chairman's Message" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="ttm-featured-wrapper">
                                    <div className="featured-thumbnail text-center">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/aboutchairman/chairman.jpg`} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 justify" style={{ margin: "auto" }}>
                                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                    <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                        <div className="ttm-team-member-single-list">
                                            <a href="https://www.shoonyeka.com/bit-principal-production/" target="_blank"><h2 className="ttm-team-member-single-title">Sri B N Lokesh</h2></a>
                                            <span className="ttm-team-member-single-position">Chairman, College Governing Council, VVCL</span>
                                            <hr />
                                            <p><b>Dear Students,</b></p>
                                            <p className="justify">Academic institutions play a vital role in assisting the youth in realizing their potential and channelizing it to face the challenges and to achieve elusive goals. We believe in giving the power of knowledge to all our students. Vokkaligara Sangha has created a benchmark in the field of global education through its contribution in various educational, Medical, Corporate and Legal sectors.</p>
                                            <p className="justify">Visveswarapura College of Law (V.V.Puram Law College) maintains high standards of academic excellence. The College creates a cordial connection between a law school education, the prospective lawyer and the firms that hire students</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 paddingtop-2 justify'>
                                <p className="justify">and ultimately clients and Society. This is evidenced in the curricular and co–curricular activities which are integrated into classroom practice resulting in high motivational levels among students and healthy cultural climate. The Staff strives to stimulate in students a holistic vision along with authentic academic information vital to translate their vision into reality.</p>
                                <p className="justify">We look forward to grow with the changing needs of the future and we hope that every student of the V.V.Pura College of Law will have the strength to meet future challenges with professional acumen and excellence and become great Social Engineers.</p>
                                <p>With Best Regards</p>
                                <p><b style={{ fontWeight: "bold" }}>Sri B N Lokesh</b><br />Chairman, College Governing Council, VVCL</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
