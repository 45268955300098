import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutPrincipal extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Principal Desk" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="ttm-featured-wrapper">
                                    <div className="featured-thumbnail text-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/aboutprincipal/principal.jpg"} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 justify" style={{ margin: "auto" }}>
                                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                    <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                        <div className="ttm-team-member-single-list">
                                            <a href="https://www.shoonyeka.com/bit-principal-production/" target="_blank"><h2 className="ttm-team-member-single-title">Dr. Sudha G</h2></a>
                                            <span className="ttm-team-member-single-position">B.A., LL.B., LL.M., Ph.D</span>
                                            <hr />
                                            <p>Dear Students,</p>
                                            <p>It is with great pleasure that I welcome you to Visveswarapura College of Law (V.V.Pura College of law), managed by the Vokkaligara Sangha.  Our college is a premier educational institution. The institution is imparting innovative and qualitative legal education with a vision to become an outstanding learning</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 paddingtop-1 justify'>
                                <p>centre and with a global outlook and human values. Democracy depends on law and lawyers for its smooth functioning.  Democracy succeeds only when the three pillars of civilization i.e., justice, equality and liberty are strong.</p>
                                <p>It is lawyers who keep the three-pillars very strong. Visveswarapura College of law is one of the prestigious law colleges in Karnataka established in the year 1972.  The college is doing Yeoman’s service to the nation.</p>
                                <p>The College has produced many eminent lawyers and judges.  Many are serving in different fields. I am proud to say that many of our students have secured ranks in the University examinations. I wish you every success in studies and future career.</p>
                                <p>With Best Regards</p>
                                <p><b style={{ fontWeight: "bold" }}>Dr. Sudha G</b><br />Principal</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
