import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from './components/layout/Footer';

// pages
import Home from './components/Home';

import AboutManagement from "./components/about/AboutManagement";
import AboutCollege from "./components/about/AboutCollege";
import AboutChairman from "./components/about/AboutChairman";
import AboutPrincipal from "./components/about/AboutPrincipal";

import ProgrammeOffered from "./components/programme/ProgrammeOffered";
import ProgrammeProcess from "./components/programme/ProgrammeProcess";

import Teaching from "./components/academics/Teaching";
import NonTeaching from "./components/academics/NonTeaching";
import SpecificFaculty from "./components/academics/SpecificFaculty";
import Calendar from './components/academics/Calendar';
import ClubsCommittees from "./components/academics/ClubsCommittees";

import LibraryAbout from './components/library/LibraryAbout';
import LibraryRules from './components/library/LibraryRules';
import LibraryInfra from './components/library/LibraryInfra';
import LibraryStaff from './components/library/LibraryStaff';

import IQACAbout from "./components/IQAC/IQACAbout";
import IQACMeeting from "./components/IQAC/IQACMeeting";
import IQACComposition from "./components/IQAC/IQACComposition";
import IQACNAAC from "./components/IQAC/IQACNAAC";

import IQACNAACCRITERION_1 from "./components/IQAC/IQACNAACCRITERION_1";
import IQACNAACCRITERION_2 from "./components/IQAC/IQACNAACCRITERION_2";
import IQACNAACCRITERION_3 from "./components/IQAC/IQACNAACCRITERION_3";
import IQACNAACCRITERION_4 from "./components/IQAC/IQACNAACCRITERION_4";
import IQACNAACCRITERION_5 from "./components/IQAC/IQACNAACCRITERION_5";
import IQACNAACCRITERION_6 from "./components/IQAC/IQACNAACCRITERION_6";
import IQACNAACCRITERION_7 from "./components/IQAC/IQACNAACCRITERION_7";

import IQACDVV from "./components/IQAC/IQACDVV";
import IQACAISHE from "./components/IQAC/IQACAISHE";

import StatutorySC from "./components/StatutoryCell/StatutorySC";
import StatutoryOBC from "./components/StatutoryCell/StatutoryOBC";
import StatutoryMinority from "./components/StatutoryCell/StatutoryMinority";
import StatutoryAntiRagging from "./components/StatutoryCell/StatutoryAntiRagging";
import StatutoryICC from "./components/StatutoryCell/StatutoryICC";
import StatutoryGrievance from "./components/StatutoryCell/StatutoryGrievance";

import Alumni from './components/Alumni';
import Gallery from "./components/Gallery";
import Contact from './components/Contact';

import StudentGrivence from "./components/StudentGrivence";

import sent from './components/sent';
import ScrollToTop from './components/layout/Gotop';

const appHistory = {basename: process.env.PUBLIC_URL};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <div id="preloader"><div id="status">&nbsp;</div></div>
        <ScrollToTop />
        <Route exact path='/' component={Home} />

        <Route path="/About-Management" component={AboutManagement} />
        <Route path="/About-College" component={AboutCollege} />
        <Route path="/About-Chairman" component={AboutChairman} />
        <Route path="/About-Principal" component={AboutPrincipal} />

        <Route path="/Programme-Offered" component={ProgrammeOffered} />
        <Route path="/Programme-Process" component={ProgrammeProcess} />

        <Route path="/Academics-Teaching" component={Teaching} />
        <Route path="/Academics-Administrative" component={NonTeaching} />
        <Route path="/Academics-Specific/:id" component={SpecificFaculty} />
        <Route path="/Academics-Calendar" component={Calendar} />
        <Route path="/Academics-Committees" component={ClubsCommittees} />

        <Route path="/Library-About" component={LibraryAbout} />
        <Route path="/Library-Rules" component={LibraryRules} />
        <Route path="/Library-Infrastructure" component={LibraryInfra} />
        <Route path="/Library-Staff" component={LibraryStaff} />

        <Route path="/IQAC-About" component={IQACAbout} />
        <Route path="/IQAC-Meeting" component={IQACMeeting} />
        <Route path="/IQAC-Composition" component={IQACComposition} />
        <Route path="/IQAC-NAAC" component={IQACNAAC} />

        <Route path="/IQAC-NAAC-CRITERION-1" component={IQACNAACCRITERION_1} />
        <Route path="/IQAC-NAAC-CRITERION-2" component={IQACNAACCRITERION_2} />
        <Route path="/IQAC-NAAC-CRITERION-3" component={IQACNAACCRITERION_3} />
        <Route path="/IQAC-NAAC-CRITERION-4" component={IQACNAACCRITERION_4} />
        <Route path="/IQAC-NAAC-CRITERION-5" component={IQACNAACCRITERION_5} />
        <Route path="/IQAC-NAAC-CRITERION-6" component={IQACNAACCRITERION_6} />
        <Route path="/IQAC-NAAC-CRITERION-7" component={IQACNAACCRITERION_7} />

        <Route path="/IQAC-DVV" component={IQACDVV} />
        <Route path="/IQAC-AISHE" component={IQACAISHE} />

        <Route path="/Statutory-SC" component={StatutorySC} />
        <Route path="/Statutory-OBC" component={StatutoryOBC} />
        <Route path="/Statutory-Minority" component={StatutoryMinority} />
        <Route path="/Statutory-AntiRagging" component={StatutoryAntiRagging} />
        <Route path="/Statutory-ICC" component={StatutoryICC} />
        <Route path="/Statutory-Grievance" component={StatutoryGrievance} />

        <Route path="/Alumni" component={Alumni} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/contact" component={Contact} />

        <Route path="/Student-Grivence" component={StudentGrivence} />

        <Route path="/Sent" component={sent} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
