import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import LibrarySidebar from './LibrarySidebar';

export default class LibraryRules extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Library Rules" image="banners/Library.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Rules and Regulations</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Users should always carry valid I.D cards to the library.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>A person desirous of using the Library shall enter his/her name, time and put signature in the register book.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>A non-member can use the library materials with the prior permission of the College Librarian.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Personal books are not allowed in the library.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Books and Bound Volumes should be handled with care.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Pages of the Books and Bound Volumes should not be folded to serve as book Marks.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Leave books on the Reading Tables after use.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Damaging and marking the pages of library books with ink or pencil is prohibited.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Smoking, eating, sleeping and talking loudly are strictly prohibited in the Library.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Silence shall be maintained as far as possible in the Library.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Mobile phone strictly prohibited in the Library.</li>
                                        </ul>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Rules for Membership</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>All the users need to be members of the library to avail the facility.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>For library membership, students should produce the fee receipt of the current year against which they will be given a library card and borrower card to avail the library book borrowing facility.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Staff members should fill up the Library borrower register for using the library facility.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Visiting faculties can have temporary membership of the library.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The borrower’s card remains valid for the period indicated on it, and the same can be renewed every academic year.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Users are requested to issue the books from the library as per the rules only.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Late fine is charged for Late Return of the books.</li>
                                        </ul>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Members Privileges</h4>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{width:"50%"}}>Category of Membership</th>
                                                <th scope="col">No. of Books</th>
                                                <th scope="col">Period of Loan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Students</td>
                                                <td>01</td>
                                                <td>15 days</td>
                                            </tr>
                                            <tr>
                                                <td>SC/ST Students (Eligible to borrow the books from the Book Bank Section)</td>
                                                <td>01</td>
                                                <td>15 days</td>
                                            </tr>
                                            <tr>
                                                <td>Administrative Staff</td>
                                                <td>02</td>
                                                <td>07 days</td>
                                            </tr>
                                            <tr>
                                                <td>Guest Faculty</td>
                                                <td>03</td>
                                                <td>30 days</td>
                                            </tr>
                                            <tr>
                                                <td>Full Time Teaching  Faculty</td>
                                                <td>10</td>
                                                <td>One Semester</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LibrarySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}