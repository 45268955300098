import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBDropdownItem,
MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class Mobilemenu extends Component {
  
state = {
  collapse1: false,
  collapseID: ''
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
}

toggleSingleCollapse = collapseId => {
  this.setState({
    ...this.state,
    [collapseId]: !this.state[collapseId]
  });
}

render() {
  return (
    <Router>
        <MDBNavbar>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Home</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Management'}>About Management</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-College'}>About College</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Chairman'}>About Chairman</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Principal'}>About Principal</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Programme-Offered'}>Programme Offered</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Programme-Process'}>Admission Process</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Academics-Teaching'}>Teaching Staff</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Academics-Administrative'}>Administrative Staff</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Academics-Calendar'}>Calendar</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Academics-Committees'}>Committees / Clubs</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Library-About'}>Library</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/IQAC-About'}>IQAC</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Statutory-SC'}>Statutory Cell</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Alumni'}>Alumni</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact'}>Contact</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Gallery'}>Gallery</MDBDropdownItem></MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBNavbar>
    </Router>
    );
  }
}

export default Mobilemenu;