import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class ProgrammeOffered extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Admission Process" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Admission to the five year B.A.LL.B., and Three year LL.B. degree programmes in the College is subject to the regulations of Bar Council of India, New Delhi, the Government of Karnataka, and Karnataka State Law University, Hubballi and the College/Management.</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Eligibility for Admission to 5 Year B.A. LL.B.</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/admission/admission-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">To be eligible for admission in the 5-year B.A., LL.B. integrated programme, the candidate should: have successfully completed Pre-University course or Senior Secondary School course ('+2') or equivalent such as (11+1, 'A' level in Senior School Leaving Certificate course) from a recognised University of India or outside or from Senior Secondary Board or equivalent, constituted or recognised by the Union or by the State Government or from any equivalent institution from a foreign country recognised by the government of that country for the purpose of issue of qualifying certificate on successful completion of the course, may apply for and be admitted into the programme. Provided that applicants who have obtained +2 Higher Secondary Pass Certificate or First Degree Certificate</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">after prosecuting studies in distance or correspondence method shall also be considered as eligible for admission to the programme. Admissions to the first year of the 5 year B.A., LL.B. integrated programme is strictly based on merit, based on the marks secured by the candidate in the qualifying examination.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Documents and Certificates Required</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>SSLC / 10th grade Original Marks Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>PUC I / 11th grade Original Marks Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>PUC II / 12th grade Original Marks Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Caste certificate and income certificate for SC/ ST/ OBC/ Cat-1 students</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Three sets of photocopies of all the above documents</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Four passport size photos</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Transfer Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Aadhar Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Migration Certificate (Only for CBSE/ ISC/ IB and Non Karnataka Students)</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Eligibility for Admission to 3 Year LL.B.</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">To be eligible for admission in the 3 year LL.B. programme, the candidate should:</p>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/admission/admission-2.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="tab-desc">Have graduated in any discipline of knowledge from a University established by an Act of Parliament or by a State Legislature or an equivalent national institution recognised as a Deemed to be University or Foreign University recognised as equivalent to the status of an Indian University by an authority competent to declare equivalence, may apply for three-year degree programme in law leading to the conferment of LL.B. degree on successful completion of the regular programme conducted under these Regulations. Be or be less than thirty years in the case of general category of applicants and thirty-five years in the case of applicants from SC, ST, and other backward communities as on 1st June. Have secured marks, which shall not be below 45%</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">(44.5% and above shall be treated as 45%) of total marks in case of general category applicants and 40% of total marks in case of SC and ST applicants. Admissions to the first year of the 3 year LL.B. programme is strictly based on merit, based on the marks secured by the candidate in the qualifying examination.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Documents and Certificates Required</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>SSLC / 10th grade Original Marks Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>PUC I / 11th grade Original Marks Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>PUC II / 12th grade Original Marks Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Transfer Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Migration Certificate (Only for Non Karnataka Students)</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Four passport size photos</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Aadhar Card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>All semesters’ original Degree marks card</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Provisional Degree Certificate/ Convocation Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Caste certificate and income certificate for SC/ ST/ OBC/ Cat-1 students</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Three sets of photocopies of all the above documents</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
