import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer clearfix">
                <a href="https://api.whatsapp.com/send?phone=6364370011" class="float" target="_blank"><i class="fab fa-whatsapp my-float"></i></a>
                <div className="second-footer ttm-textcolor-white ttm-bgcolor-darkgrey">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
                                <div className="widget widget_text clearfix">
                                    <h3 className="widget-title">About Our College</h3>
                                    <div className="textwidget widget-text">Visveswarapura College of Law (V.V.Pura College of law) was established by the Rajya Vokkaligara Sangha, Bengaluru in the year 1972. Its founder Principal was a great legal luminary and former Advocate General of Karnataka High Court Prof.B.T.Parthasarathy.</div>
                                    <div className="social-icons circle social-hover" style={{ paddingTop: "1.5rem" }}>
                                        <ul className="list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/VVPCL/?ref=page_internal" className="tooltip-top rounded-circle" target="_blank" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                            <li className="social-instagram"><a href="https://www.instagram.com/vvcl_for_advocacy/" className="tooltip-top rounded-circle" target="_blank" data-tooltip="instagram"><i className="fab fa-instagram" /></a></li>
                                            <li className="social-facebook"><a href="https://www.youtube.com/@visveswarapuracollegeoflaw9120" className="tooltip-top rounded-circle" target="_blank" data-tooltip="Youtube"><i className="fab fa-youtube" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget link-widget clearfix">
                                    <h3 className="widget-title">Quick Links</h3>
                                    <ul id="menu-footer-services">
                                        <li><a href="https://kslu.karnataka.gov.in/english" target="_blank">KSLU</a></li>
                                        <li><a href="http://www.barcouncilofindia.org/" target="_blank">BCI</a></li>
                                        <li><a href="https://www.ugc.ac.in/" target="_blank">UGC</a></li>
                                        <li><a href="http://www.naac.gov.in/" target="_blank">NAAC</a></li>
                                        <li><a href="https://ssp.postmatric.karnataka.gov.in/" target="_blank">SSP</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-767-mb-40">
                                <div className="widget flicker_widget clearfix">
                                    <h3 className="widget-title">Important Links</h3>
                                    <ul id="menu-footer-services">
                                        <li><Link to="/Student-Grivence">Student Grivence</Link></li>
                                        <li><Link to="/Admission-Process">Admission Process</Link></li>
                                        <li><Link to="/Library-About">Library</Link></li>
                                        <li><Link to="/IQAC-About">About</Link></li>
                                        <li><Link to="/Gallery">Gallery</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                    <div className="container-xl">
                        <div className="copyright" style={{ textAlign: "center" }}>
                            <span>Copyright © 2023&nbsp; by <a to="https://shoonyeka.com" target="_blank">Shoonyeka Solutions (OPC) Pvt Ltd</a></span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}