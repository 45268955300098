import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown"><a>About</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/About-Management'>About Management</NavLink></li>
                            <li><NavLink to='/About-College'>About College</NavLink></li>
                            <li><NavLink to='/About-Chairman'>Chairman Message</NavLink></li>
                            <li><NavLink to='/About-Principal'>Principal’s Desk</NavLink></li>
                            <li><NavLink to="/Alumni">Alumni</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Programme</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Programme-Offered'>Programme Offered</NavLink></li>
                            <li><NavLink to='/Programme-Process'>Admission Process</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Academics</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Academics-Teaching'>Teaching Staff</NavLink></li>
                            <li><NavLink to='/Academics-Administrative'>Administrative Staff</NavLink></li>
                            <li><NavLink to='/Academics-Calendar'>Calendar</NavLink></li>
                            <li><NavLink to='/Academics-Committees'>Committees / Clubs</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Library</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Library-About'>About Library</NavLink></li>
                            <li><NavLink to='/Library-Rules'>Rules and Regulation</NavLink></li>
                            <li><NavLink to='/Library-Infrastructure'>Infrastructure / Facilities</NavLink></li>
                            <li><NavLink to='/Library-Staff'>Library Staff Information</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>IQAC/NAAC</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/IQAC-About'>About IQAC</NavLink></li>
                            <li><NavLink to='/IQAC-Meeting'>Minutes of meetings</NavLink></li>
                            <li><NavLink to='/IQAC-Activities'>IQAC Activities</NavLink></li>
                            <li><NavLink to='/IQAC-NAAC'>NAAC</NavLink></li>
                            <li><NavLink to='/IQAC-AISHE'>AISHE</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Statutory Cell</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Statutory-SC'>SC/ST Cell</NavLink></li>
                            <li><NavLink to='/Statutory-OBC'>OBC Cell</NavLink></li>
                            <li><NavLink to='/Statutory-Minority'>Minority Cell</NavLink></li>
                            <li><NavLink to='/Statutory-AntiRagging'>Anti-Ragging Cell</NavLink></li>
                            <li><NavLink to='/Statutory-ICC'>Prevention of Sexual Harassment Committee (ICC)</NavLink></li>
                            <li><NavLink to='/Statutory-Grievance'>Student Grievance Redressal Committee</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/Gallery">Gallery</NavLink></li>
                    <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </nav>

        )
    }
}