import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import LibrarySidebar from './LibrarySidebar';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import ModalImage from "react-modal-image";

export default class LibraryInfra extends Component {

    state = { 
        Datas: [
            {
                Image: process.env.PUBLIC_URL + "/assets/images/library/library-2.jpg",
                Name: "Reference Section",
            },
            {
                Image: process.env.PUBLIC_URL + "/assets/images/library/library-3.jpg",
                Name: "Issue Section",
            },
            {
                Image: process.env.PUBLIC_URL + "/assets/images/library/library-4.jpg",
                Name: "Periodical Section",
            },
            {
                Image: process.env.PUBLIC_URL + "/assets/images/library/library-5.jpg",
                Name: "Digital Library",
            },
            {
                Image: process.env.PUBLIC_URL + "/assets/images/library/library-6.jpg",
                Name: "Book Bank Section",
            },
            {
                Image: process.env.PUBLIC_URL + "/assets/images/library/library-7.jpg",
                Name: "Reading Room",
            }
        ] 
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Library Infrastructure" image="banners/Library.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="row paddingtop-2">
                                        {
                                            this.state.Datas.length === 0 ? <Loading /> :
                                                this.state.Datas === "NO_999" ? <NoData /> :
                                                    this.state.Datas.map((data, i) => (
                                                        <div className="col-lg-4" key={i} style={{textAlign:"center"}}>
                                                            <div className="gallery-box">
                                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                                            </div>
                                                            <br/>
                                                            <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                                            <p style={{ color: "black" }}>{data.Description}</p>
                                                        </div>
                                                    ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LibrarySidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
