import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import StatutorySidebar from './StatutorySidebar';

export default class StatutorySC extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Committess.jpg" />
                
                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">SC/ST Cell</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/statutory/statutory-2.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">The Scheduled Caste (SC) and Scheduled Tribes (ST) Cell in an institute promotes the special interests of students in the reserved category. It is expected to provide special inputs in areas where the students experience difficulties. It is important statutory Cell. The aim of the cell to give the information on the Government scheme provided by the social welfare department for the SC/ST community student, regarding the books, scholarship, schemes available for them.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">This cell maintained the data base of SC/ST student and also the performance of the student. This cell worked for the welfare of the SC/ST Community student.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Objectives</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To counsel and guide SC/ ST students and help them to manage academic and personal issues of college life effectively.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To ensure provisions of an environment where all such students feel safe and secure.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To provide prompt counseling for any emotional emergencies arising on account of any event at the campus.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To provide the mechanism to redress the grievance of SC/ST students, if any</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To ensure protection and reservation as provided in the constitution of India.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To arrange for special opportunities to enhance the carrier growth</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To aware the SC/ST students regarding various scholarships program of State Govt. and UGC.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To take such follow up measures to achieve the objectives and targets laid down by the Govt. of India and the UGC.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Activities</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To collect reports and information of State Govt. and UGC’s orders on various aspects of education, employment of SC/ST & OBC Students.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To circulate State Govt.and UGC’s decisions about different scholarship programs.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To communicate with the students and motivate them for better future planning.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}