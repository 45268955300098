import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class StatutorySidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to={'/Statutory-SC'}>SC/ST Cell</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Statutory-OBC'}>OBC Cell</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Statutory-Minority'}>Minority Cell</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Statutory-AntiRagging'}>Anti-Ragging Cell</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Statutory-ICC'}>Prevention of Sexual Harassment Committee (ICC)</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Statutory-Grievance'}>Student Grievance Redressal Committee</NavLink></li>
                </ul>
            </div>
        )
    }
}