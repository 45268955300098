import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class ClubsCommittees extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="Committees / Clubs" image="banners/Committess.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">1. Moot Court Society</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The moot court activity is one of the best practical exercises for Law students. It’s part of co-curricular activity. The Moot court society selects the mooters representative from the college to participated in inter college moot competitions. The moot court society develops the legal research skills specially in the drafting of memorandum and demonstrates the moot trials. Composition of the Committee</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">2. Student Welfare Committee</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-2.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">A Student Welfare committee is an extra-curricular and co-curricular activity for the student’s welfare. The committee support for welfare of students provides all welfare measures to students like sports and cultural fest etc. The welfare committee recognizes and identifies the students’ talents in the various supports students to participate in inter college fest and university youth fest etc</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">3. Cultural Committee</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-3.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para"><b>“Cultural differences should not separate us from each other, but rather cultural diversity brings a collective strength that can benefit all of humanity”.</b> By Robert Alan.The cultural committee is one of the extra-curricular activities for students. The cultural committee helps students’ to know importance of local, regional and national cultural and   traditional values of our nation.  The committee specially focuses on the folk Dance, folk orchestra and etc, our students participated in inter college fest, university youth fest and won many prizes in inter college fest and university youth fest. It provides a platform to talented persons to showcase their extra-curricular activities. The committee organizes annual fest, college events etc.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">4. Sports Committee</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-4.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The College provides a wide range of sports, recreation and leisure activities for students. The primary goal is to stimulate the growth, development, and retention of students in a safe environment that develops fitness and wellness, social interaction and leadership opportunities. The institution provides students with a platform to participate in various college, university and state level tournaments. Students are trained by professional coaches as well. The college offers various facilities for indoor and outdoor games. The sports activities include Chess, Carom, Badminton, Volleyball, Cricket, and Throw ball etc. The college also conducts various sports tournaments.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">5. Eco Club</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-5.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para"><b>“Invest in plants & enliven our future generations”</b> The Eco Club is one of very important clubs in the academic activities for students. The Eco Club tries to increase the mass awareness to environmental problems and prevention to protect the mother earth from pollutions among the students, faculty, staff, management etc. To know the importance of environment, problems from pollution and protect the environment from pollution.  The environmental protection is the responsibility of the entire citizen. The Eco clubs associated with AIKYA Foundation Non-profit organization and People for Animals (NGO) Bangalore</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">6. Human Rights Club</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-6.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para"><b>“To deny people their human rights is to challenge their very humanity”</b> Nelson Mandela. The United Nations Organization adopted 10 December 1948 as world Human Rights Day; the human rights club organizes the various awareness programs relating to human rights activities. The club actively advocates in against the violation human rights. The club conducted many guest lecturers, workshop, and seminars regarding human rights.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">7. Debate Club</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-7.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para"><b>“Universities should be about more than developing work skills. They must also be about producing civic-minded and critically engaged citizens - citizens who can engage in debate, dialogue and bear witness to a different and critical sense of remembering, agency, ethics and collective resistance”.</b>Henry Giroux. The Debate club was established in academic year 2017-18, the club organizes Stale Level Debate Competitions (Kannada and English) every year. The debate club gives the opportunity to all the students participating in intra college debate competitions and selects college representatives for both Kannada and English debate. The club actively conducts current issues intra college debate programme.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">8. Examination Committee</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-8.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The Examination Committee is conducting the final semester examination and internal assessment and practical examinations, maintains the records. To help and ensure smooth and orderly conduct of examinations in the institute as per guidelines issued by Karnataka State Law University from time to time.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">9. Seminar / Conference Committee</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-9.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The Seminars / conference committee of Visveswarapura College of Law encourage Faculty members and students to Participate in the State/ National/ International level seminar. The Committee also conducts National Level Conference in the name of founder of the college Prof. B.T. Parathsarathy. The committee conducting periodical guest lectures on emerging legal issues.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">10. Career Guidance / Placement Cell</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-10.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The Placement is very important to outgoing students. The Visveswarapura College of Law Placement cell conducts career guidance to final year students in collaboration with Karnataka State Law University, Hubballi. The main aim of the cell is encouraging the students for internships with highly reputed Advocate Associates, NGO’s etc.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">11. Red Cross Society</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-11.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The society born to protect life and health. It is an international and national forum serving the needy people and reduces the injuries disaster and death of the people. Every year with the collaboration with this society we conducted blood donation camp. In this 200 student participated in the camp.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">12. Electoral literacy club</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-12.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Electoral Literacy Club is a platform to engage College students through interesting activities and hands-on experience to sensitise them on their electoral rights and familiarise them with the electoral process of registration and voting.  The main objective of ELC is to educate the targeted populations about voter registration, electoral process and related matters through hands on experience; To facilitate EVM and VVPAT familiarization and education about the robustness of EVM as well as the integrity of the electoral process using EVMs; To help the target audience understand the value of their vote and exercise their suffrage right in a confident, comfortable and ethical manner; and to facilitate voter registration for its eligible members who are not yet registered.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">13. Legal Service Clinic</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-13.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The purpose of establishing Legal Services Clinic is to impart clinical legal education as a part of practical training programme formulated by the Bar Council of India and is also connected with State Legal Services Authority to encourage the students to commit to social service to help the weaker sections of the community. It has dual objectives one is spreading legal awareness and second is providing free legal assistance to the socially and the economically backward. sections of the society who have difficulty in accessing the judicial system by organizing legal aid camps and legal literacy awareness program in rural areas.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">14. NSS (National Service Scheme Cell)</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/club/club-14.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">National Service Scheme provides diversified opportunities to students in colleges to develop their personality through community services. The objective of NSS is to create social awareness among the students and the all-round development of their personality. The N.S.S. Unit of our College conducts regular and special camps, legal awareness programmes, demonstrations, personality development programmes etc. for the volunteers. Blood donation to the needy and required patients is a creditable activity of the N.S.S.</p>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
