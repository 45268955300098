import React, { Component } from 'react';
import Header from './layout/Header';
import Banner from './Common/Banner';

export class StudentGrivence extends Component {

    render() {
        return (
            <div className="site-main">
                <Header />
                <Banner title="Student Grivence" image="banners/Contact.jpg" />
                <section className="ttm-row zero-padding-section clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">
                            <div className="col-lg-5">
                                <div className="spacing-9">
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Come Visit Us At</h5>
                                            <h2 className="title">Our Address</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti-location-pin" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h5>College Address</h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Visveswarapura College of Law, Krishna Rajendra Road (K.R.Road), Visveswarapura (V.V.Pura ), Bangalore-560 004.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="fa fa-phone" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Our Phone Number</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>(080) 26615851</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti ti-email" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h5>Our Email</h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>principalvvcl@gmail.com</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-bg-layer-inner" />
                                    </div>
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Send Message</h5>
                                            <h2 className="title">Drop Us A Line</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action="contactform.php">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="name" type="text" className="form-control bg-white" placeholder="Student Name*" required="required" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="name" type="text" className="form-control bg-white" placeholder="Student Coure*" required="required" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="name" type="text" className="form-control bg-white" placeholder="Student Year*" required="required" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="phone" type="text" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="email" type="text" placeholder="Email Address*" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="reg" type="text" placeholder="Student Registration Number" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group" name="type" >
                                                <select className="form-control bg-white" aria-label="Default select example">
                                                    <option value="1">Anti Ragging</option>
                                                    <option value="2">Sexual Harassment</option>
                                                    <option value="3">Student Grievance</option>
                                                    <option value="4">SC / ST Cell</option>
                                                    <option value="5">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="Msg" rows={5} placeholder="Write A Message..." required="required" className="form-control bg-white" defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="text-left">
                                                <button type="submit" id="submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" value>Send Complaint</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default StudentGrivence;


