import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import StatutorySidebar from './StatutorySidebar';

export default class StatutoryGrievance extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Committess.jpg" />
                
                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Student Grievance Redressal Committee</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/statutory/statutory-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">The College has a Students' Grievance Redressal Committee. The functions of the Committee are to look into the complaints lodged by any student, and judge its merit. The Grievance Redressal Committee is also empowered to look into matters of harassment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Objective</h5>
                                        </div>
                                    </div>
                                    <p className="about-para">o provide a mechanism to students of the college to air out their grievances and to provide redressal for the same so that they have smooth tenure at the college from the day of admission to their graduation.</p>
                                    <div className="section-title with-desc text-left clearfix paddingtop-1">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Grievance Redressal Procedure</h5>
                                        </div>
                                    </div>
                                    <p className="about-para">The students have to place their grievances in the suggestions box/complaint book placed various points like college office, library, hostels, and grievance redressal cell. The coordinator in turn intimates the matter to the committee for necessary action. Final report based on grievance received and resolved will be submitted to the Principal and further course of action will be decided and the same shall be intimated to the students.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}