import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import StatutorySidebar from './StatutorySidebar';

export default class StatutoryOBC extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Committess.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">OBC Cell</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/statutory/statutory-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">The OBC Cell in the college was established with the purpose to empower the OBC students. The college takes special interest in facilitating financial support to students from these communities from government agencies and other sources. The cell is always engaged to solve the various problems of OBC students.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Objectives</h5>
                                        </div>
                                    </div>
                                    <p>OBC Cell has been created in the college to resolve issues related to the OBC Students. Following are the important Objectives of OBC Cell:</p>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To aware the OBC students regarding various scholarships program of State& Central Government.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To council and guide OBC students about various issues and help them to manage academic activities.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To resolve problems related to OBC students.</li>
                                    </ul>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Activities</h5>
                                        </div>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To circulate State Government and UGC’s decisions about different scholarship programs.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To communicate with the students and motivate them for better future planning.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To deal with representations received from Other Backward Classes (OBC) Candidates regarding their admission, scholarships and other similar matters in the College.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}