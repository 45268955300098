import React, { Component } from 'react';
import CountUp from 'react-countup';
import Slider from 'react-slick';

export default class Number extends Component {
    render() {
        return (
            <div>
                {/* details-section */}
                <section className="ttm-row zero-padding-section ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="ttm-bgcolor-white ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-bg-layer-inner" />
                                    </div>
                                    <div className="layer-content">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Number.jpg`} title="single-img-five" alt="single-img-five" />
                                                </div>
                                                <div className="about-overlay-02">
                                                    <h3>51 Years of</h3>
                                                    <p>Teaching Service</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="res-991-mt-30">
                                                    <div className="section-title with-desc clearfix">
                                                        <div className="title-header">
                                                            <h5>About VVCL</h5>
                                                            <h2 className="title">Visveswarapura College of Law was established <span>1972.</span></h2>
                                                        </div>
                                                        <div className="title-desc">
                                                            <p>Its founder Principal was a great legal luminary and former Advocate General of Karnataka High Court Prof.B.T.Parthasarathy. Our College is one of the prestigious Law Colleges in Karnataka, doing Yeoman’s service to the nation. Our College is providing great educational opportunities to both Rural and Urban Students, to study Law courses. The institution is imparting innovative and quality legal education with a vision to become an outstanding learning center with a global outlook and human values.</p>
                                                            <p>Its alumni have settled in ranges of legal professions and have left their enduring mark in the legal field in various parts of India and abroad. It is committed to focus on multi-disciplinary approach to enable more students to access affordable and quality legal education along with multiple skill sets. It focuses on creating a system by which participation in moots and internships in courts and similar institutions for students so they are able to hold their own from day one.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* number-section */}
                <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img10 mt_225 res-991-mt-0 clearfix">
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-3 col-6">
                                <div className="ttm-fid inside ttm-fid-view-topicon">
                                    <div className="ttm-fid-icon-wrapper">
                                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                            <i className="flaticon flaticon-online-library" />
                                        </div>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4>
                                            <CountUp start={0} end={19540} duration={30} />
                                        </h4>
                                        <h3 className="ttm-fid-title">Graduated</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="ttm-fid inside ttm-fid-view-topicon">
                                    <div className="ttm-fid-icon-wrapper">
                                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                            <i className="flaticon flaticon-developer" />
                                        </div>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4>
                                            <CountUp start={0} end={16330} duration={30} />
                                        </h4>
                                        <h3 className="ttm-fid-title">Enrolled as an Advocates</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="ttm-fid inside ttm-fid-view-topicon">
                                    <div className="ttm-fid-icon-wrapper">
                                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                            <i className="flaticon flaticon-bar-chart" />
                                        </div>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4>
                                            <CountUp start={0} end={52} duration={30} />
                                        </h4>
                                        <h3 className="ttm-fid-title">Ranks & Gold Medals</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="ttm-fid inside ttm-fid-view-topicon">
                                    <div className="ttm-fid-icon-wrapper">
                                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                            <i className="flaticon flaticon-intelligent" />
                                        </div>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4>
                                            <CountUp start={0} end={31} duration={30} />
                                        </h4>
                                        <h3 className="ttm-fid-title">Judges</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* testimonial-section */}
                <section className="ttm-row only-testimonial-section bg-img11 pb-0 res-991-pb-0 clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title with-desc text-center clearfix">
                                    <div className="title-header">
                                        <h5>Empowering the society with self</h5>
                                        <h2 className="title">Law is the bond of the <span>society</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Slider className="testimonial-slide style1 pt-0 slick_slider res-991-pb-0" arrows={false} dots={false}>
                                    <div className="testimonials text-center">
                                        <div className="testimonial-content">
                                            <div className="testimonial-avatar">
                                                <div className="testimonial-img">
                                                    <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/home/testimonial_1.jpg"} />
                                                </div>
                                            </div>
                                            <blockquote>I am delighted to share my testimonial for Visveswarapura College of Law, where I completed my LL.B. The institution has played an instrumental role in shaping my professional career. Thanks to the supportive and experienced faculty, I feel well-equipped and confident.<br /><br />In addition to the course curriculum, Visveswarapura College of Law offered a range of activities that further honed my overall skills in research and public speaking. These activities included court/ jail/ mediation centre visits, lectures, debates and cultural activities. I actively participated in these activities, and I am proud to have won several accolades, including ‘Best Lady Advocate’ and ‘Winners’ at National level moot court competition.<br /><br />It is a privilege to be associated with Visveswarapura College of Law. I would highly recommend it to anyone seeking to pursue legal education with a practical outlook.</blockquote><br />
                                            <div className="testimonial-caption">
                                                <h6>CA. Priya Kothari</h6>
                                                <label>Direct Tax Consultant at H C Khincha & Associates</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="testimonials text-center">
                                        <div className="testimonial-content">
                                            <div className="testimonial-avatar">
                                                <div className="testimonial-img">
                                                    <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/home/testimonial_2.jpg"} />
                                                </div>
                                            </div>
                                            <blockquote>In today’s modern age where Legal Tech, Automation, Research & Globalization are the need of the hour, VVPCL has empowered and equipped me to be creative, different and deliver. It undoubtedly facilitated to further develop my analytical thinking, and to embrace a more critical view on legal matters and their solutions.<br /><br />VVPCL’s eminent and qualified professors and lecturers with decades of experiences in the subjects they impart not only helped me to gain knowledge of the Indian laws and legal system but also helped me to further develop my thought process and to embrace a contemporary approach towards modern day legal issues. It was not always the lectures and classroom coaching which enabled me but also the various Sporting events, Moot Courts, Model Parliament, Debates, Cultural events, etc.<br /><br />VVPLC is not just an institute but is a family where future leaders in the legal field are made. I will always be indebted and grateful to all the teaching & non-teaching staffs, the management, and my fellow classmates of this family for teaching me law and life.</blockquote><br />
                                            <div className="testimonial-caption">
                                                <h6>Abilash Charles</h6>
                                                <label>Associate Director, Pricewaterhouse Coopers LLP, India</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="testimonials text-center">
                                        <div className="testimonial-content">
                                            <div className="testimonial-avatar">
                                                <div className="testimonial-img">
                                                    <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/home/testimonial_3.jpg"} />
                                                </div>
                                            </div>
                                            <blockquote>The five years that I had spent at VVPCL as a student were fantastic for a whole host of reasons. The aim of any college education is obviously to get a degree at the end, and I've found that VVPCL is a great place to study, not least because it's a law school that make you want to learn. The professors and lecturers are all experts in their fields, which mean they have a passion for their subjects that is infectious. VVPCL not only ingrained in me the required confidence but also empowered me with courage to excel in the professional world.”</blockquote><br />
                                            <div className="testimonial-caption">
                                                <h6>Pradeep Kaliannan</h6>
                                                <label>Global Delivery Leader – Entity Compliance & Governance, and Legal Advisory Support Ernst & Young LLP - Global Delivery Services</label>
                                            </div>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
